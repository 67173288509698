<template>
  <v-card
    class="mt-3 d-flex flex-column"
    :style="urgencyCard(surveyObj.priority)"
    height="100%"
  >
    <v-card-title>
      <v-row>
        <v-col
          cols="10"
        >
          <h5>{{ surveyObj.title }}</h5>
        </v-col>
        <v-col
          class="d-flex justify-end"
          cols="2"
        >
          <survey-menu
            v-if="loggedUser.role === 'professional' && !surveyReadOnly"
            @editSurvey="is_show.edit=true"
            @deleteSurvey="is_show.delete=true"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text style="overflow-y: auto; min-height:70px">
      <v-row>
        <v-col>
          <div v-html="surveyObj.description"></div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions>
      <template>
        <v-chip
          v-if="showStatus"
          label
        >
          {{ surveySettingStatusName }}
        </v-chip>
        <v-spacer />
        <v-chip
          v-if="!surveyStatusNotWorkable"
          class="mb-2"
          color="green"
          text-color="white"
          :to="{name:'procedureSurveysView',params:{id:surveyObj.procedure_id,sid:surveyObj.id}}"
        >
          Entra e Compila
        </v-chip>
      </template>
    </v-card-actions>
    <edit-survey
      :is_show="is_show.edit"
      :survey-data="surveyObj"
      @close="is_show.edit=false"
      @reload-data="reloadData"
    />
    <delete-survey
      :is_show="is_show.delete"
      :survey-data="surveyObj"
      @close="is_show.delete=false"
      @reload-data="reloadData"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import SurveyMenu from '@/components/menus/survey-menu.vue'
import EditSurvey from '@/components/modals/edit-survey.vue'
import DeleteSurvey from '@/components/modals/delete-survey.vue'

export default {
  name: 'SurveyCard',
  components: {
    SurveyMenu,
    EditSurvey,
    DeleteSurvey,
  },
  props: {
    surveyObj: {
      type: Object,
      required: true,
    },
    surveyReadOnly: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      is_show: {
        edit: false,
        delete: false,
        confirm: false,
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUser']),
    isWorkable() {
      if (this.loggedUser.role === 'user') {
        return !(this.surveyStatusNotWorkable)
      }

      return false
    },
    surveySettingStatusName() {
      return this.surveyObj.survey_setting_status.name
    },
    surveyStatusClosed() {
      return this.surveyObj.survey_setting_status.is_closed
    },
    surveyStatusCompleted() {
      return this.surveyObj.survey_setting_status.is_completed
    },
    surveyStatusAccepted() {
      return this.surveyObj.survey_setting_status.is_accepted
    },
    surveyStatusNotWorkable() {
      return this.surveyObj.survey_setting_status.is_not_workable
    },
  },
  methods: {
    urgencyCard(priority) {
      if (priority === 'high') {
        return 'border-top:10px solid #ffccbc;'
      } if (priority === 'low') {
        return 'border-top:10px solid #c8e6c9;'
      }

      return 'border-top:10px solid #c5cae9;'
    },
    goToSurveyDetail(sid) {
      this.$router.push({
        name: 'procedureSurveysView',
        params: {
          id: this.surveyObj.procedure_id,
          sid,
        },
      })
    },
    reloadData() {
      this.$emit('reloadData')
    },
  },
}
</script>
