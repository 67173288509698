import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-3 d-flex flex-column",style:(_vm.urgencyCard(_vm.surveyObj.priority)),attrs:{"height":"100%"}},[_c(VCardTitle,[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c('h5',[_vm._v(_vm._s(_vm.surveyObj.title))])]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[(_vm.loggedUser.role === 'professional' && !_vm.surveyReadOnly)?_c('survey-menu',{on:{"editSurvey":function($event){_vm.is_show.edit=true},"deleteSurvey":function($event){_vm.is_show.delete=true}}}):_vm._e()],1)],1)],1),_c(VCardText,{staticStyle:{"overflow-y":"auto","min-height":"70px"}},[_c(VRow,[_c(VCol,[_c('div',{domProps:{"innerHTML":_vm._s(_vm.surveyObj.description)}})])],1)],1),_c(VSpacer),_c(VCardActions,[[(_vm.showStatus)?_c(VChip,{attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.surveySettingStatusName)+" ")]):_vm._e(),_c(VSpacer),(!_vm.surveyStatusNotWorkable)?_c(VChip,{staticClass:"mb-2",attrs:{"color":"green","text-color":"white","to":{name:'procedureSurveysView',params:{id:_vm.surveyObj.procedure_id,sid:_vm.surveyObj.id}}}},[_vm._v(" Entra e Compila ")]):_vm._e()]],2),_c('edit-survey',{attrs:{"is_show":_vm.is_show.edit,"survey-data":_vm.surveyObj},on:{"close":function($event){_vm.is_show.edit=false},"reload-data":_vm.reloadData}}),_c('delete-survey',{attrs:{"is_show":_vm.is_show.delete,"survey-data":_vm.surveyObj},on:{"close":function($event){_vm.is_show.delete=false},"reload-data":_vm.reloadData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }