<template>
    <v-menu
        v-if="loggedUser.role === 'professional'"
        bottom
        left
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item
                @click="editModalClick"
            >
                <v-list-item-title>
                    <v-icon
                        left
                        small
                    >
                        mdi-pencil
                    </v-icon>
                    Modifica
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                @click="deleteModalClick"
            >
                <v-list-item-title>
                    <v-icon
                        left
                        small
                    >
                        mdi-delete
                    </v-icon>
                    Elimina
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "survey-menu",
    computed: {
        ...mapGetters(['loggedUser'])
    },
    methods: {
        editModalClick(){
            this.$emit('editSurvey')
        },
        deleteModalClick(){
            this.$emit('deleteSurvey')
        }
    }
}
</script>