<template>
  <div>
    <procedure-card
      no-survey-icon
      :procedure-obj="procedureData"
      :procedure-enter-btn="false"
      :redirect-to-detail="true"
    />
    <v-divider></v-divider>

    <!-- FOLDERS -->
    <v-row class="mt-3">
      <v-col
        v-for="(folder,idx) in level0Folders"
        :key="'F'+idx"
        cols="12"
        sm="12"
        md="3"
      >
        <folder-card
          folder-route="procedureArchivedFolderView"
          :folder-obj="folder"
          :folder-permissions="folder.folder_permissions"
          :go-to-folder-target-id="folder.procedure_id"
          :api-target-id="folder.procedure_id"
          :api-routes="apiRoutes"
          is-archived
        />
      </v-col>
    </v-row>

    <!--- DOCUMENTS -->
    <v-row>
      <v-col
        v-for="(doc,idx) in procedureData.documents"
        :key="idx"
        cols="12"
        sm="12"
        md="4"
      >
        <document-card
          :document-obj="doc"
          :document-read-only="true"
          :document-route="procedureRoute"
          :show-status="false"
        />
      </v-col>

      <!--- SURVEYS -->
      <v-col
        v-for="(srv,idx) in procedureData.surveys"
        :key="'S'+idx"
        cols="12"
        sm="12"
        md="4"
      >
        <survey-card
          :survey-obj="srv"
          :survey-read-only="true"
          :show-status="false"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ProcedureCard from '@/components/cards/procedure-card.vue'
import DocumentCard from '@/components/cards/document-card.vue'
import SurveyCard from '@/components/cards/survey-card.vue'
import FolderCard from '@/components/cards/folder-card.vue'

export default {
  components: {
    FolderCard,
    ProcedureCard,
    DocumentCard,
    SurveyCard,
  },
  data() {
    return {
      procedureData: {},
      mapExpirationChip: {
        expired: 'Scaduta',
      },
      apiRoutes: {
        editApiMethod: 'editFolder',
        deleteApiMethod: 'destroyFolder',
        permGetApiMethod: 'getFolderPermissions',
        permSetApiMethod: 'setFolderPermissions',
        permEditApiMethod: 'updateFolderPermission',
        permDeleteApiMethod: 'deleteFolderPermission',
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'archivedProcedureList']),
    procedureRoute() {
      return this.procedureData.status === 'archived' ? 'archivedProcedureDocumentsView' : 'procedureDocumentsView'
    },
    level0Folders() {
      if (this.procedureData.folders) {
        return this.procedureData.folders.filter(item => item.parent_id === 0)
      }

      return []
    },
    level0Documents() {
      if (this.procedureData.documents) {
        return this.procedureData.documents.filter(item => item.folder_id === 0)
      }

      return []
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const procedureId = parseInt(this.$route.params.id, 10)
      this.procedureData = await this.archivedProcedureList.find(item => item.id === procedureId)
    },
  },
}
</script>

<style scoped>

</style>
